<template>
  <v-row>
    <v-col class="pt-0">
      <!-- <skeleton-groups v-if="loadingMembers" /> -->
      <v-col class="px-0" style="z-index: 0;">
        <div class="mt-0 pa-0 d-flex">
          <div class="pa-0" style="flex:0; min-width: 350px;">
            <div style="position:fixed!important;">
              <v-card class="pa-0 pb-16 white-0" flat width="350" height="calc(100vh - 220px)">
                <v-toolbar color="transparent" flat height="46">
                  <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                    <v-row align="center" no-gutters>
                      <v-icon size="18" left color="grey-500">mdi-account</v-icon>
                      Usuario
                    </v-row>
                  </v-toolbar-title>
                </v-toolbar>
                <v-divider />
                <v-card-text class="pa-0" style="min-height: calc(100vh - 485px);">
                  <!-- form-->
                  <skeleton-groups v-if="loadingMembers" class="mt-5" type="inf" />
                  <v-list class="v-list-form-extended-medium transparent py-5 px-5" v-else>
                    <v-toolbar-title class="subtitle-2 grey-500--text font-weight-semibold pb-2">
                      <v-row align="center" no-gutters>
                        Información básica
                      </v-row>
                    </v-toolbar-title>
                    <v-list-item class="py-2">
                      <v-list-item-title>Nombre</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-avatar v-if="loadingMembers" tile class="grey-50 rounded-pill ml-1" height="15" width="220" />
                        <template v-else>
                          <span class="body-2 text--disabled font-italic" v-if="['', ' '].includes(editable.name)"> Sin especificar</span>
                          <span class="text-capitalize grey-300--text" v-else> {{editable.name | lower}}</span>
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="mt-0">
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-avatar v-if="loadingMembers" tile class="grey-50 rounded-pill ml-1" height="15" width="280" />
                        <template v-else>
                          <span class="grey-300--text" v-if="editable.email"> {{editable.email}}</span>
                          <span class="body-2 text--disabled font-italic" v-else> Sin especificar</span>
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <!-- end form-->
                </v-card-text>
                <v-divider class="mx-6" :class="{'mt-n7' : loadingMembers}" />
                <v-card-actions class="px-5 pt-5" style="display: block">
                  <p class="d-block body-1 grey-300--text">
                    ¿Qué ocurre cuando se desactiva un usuarios?
                    <span class="d-block mt-4">1. El usuario ya no podrá trabajar en el espacio de trabajo.</span>
                    <span class="d-block mt-2 pb-2">2. Toda la información creada por el usuario seguirá estando disponible.</span>
                  </p>
                  <v-btn v-if="$helpers.hasPermission(['delete_member'])" @click="dialogDelete=true" outlined style="border: 1px solid var(--light-red-500)" :ripple="false">
                    <div class="red-500--text d-flex align-center">
                      <v-icon color="red-500">mdi-account-off</v-icon>
                      Desactivar usuario
                    </div>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </div>
          <div class="pa-0 pb-16 ml-8" style="flex:1; min-width: 350px;">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="subtitle-1 grey-500--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <v-icon size="18" left color="grey-500">mdi-account</v-icon>
                  Permisos del usuario
                </v-row>
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-row align="center" justify="center" class="mr-3" v-if="$route.name === 'AccountGroupsUpdate'">
                  <v-btn @click="dialogPermissions=true" outlined>
                    <v-icon left>mdi-account-group</v-icon>
                    Lista de usuarios
                  </v-btn>
                </v-row>
              </v-toolbar-items>
            </v-toolbar>
            <!-- roles -->
            <v-row class="mt-2"  align="start" no-gutters>
              <v-col class="px-0">
                <v-card class="pa-0 white-0" flat>
                  <div>
                    <v-toolbar color="transparent" flat height="46">
                      <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                        <v-row align="center" no-gutters>
                          <v-icon size="18" left color="grey-500">mdi-account-group</v-icon>
                          Roles
                        </v-row>
                      </v-toolbar-title>
                      <v-spacer />
                      <v-toolbar-items>
                        <v-row align="center" justify="center" class="mr-6">
                          <v-btn text color="blue-500" :to="{ name: 'AccountGroupsCreate' }" target="_blank">
                            Crear nuevo rol
                          </v-btn>
                        </v-row>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-col class="px-5">
                        <skeleton-groups v-if="loading || loadingMembers" type="permissions" />
                        <template v-else>
                          <template v-if="currentGroupsList.length">
                          <v-col class="pa-0" v-for="(group, index) in currentGroupsList" :key="index">
                            <v-list class="v-list-1 transparent pa-0">
                              <v-list-item class="pa-0 py-2">
                                <v-list-item-title class="grey-300--text">
                                  <v-btn text color="blue-500" @click="handleGroup(group)">{{group.name}}</v-btn>
                                  <div class="mt-n1">
                                    <span class="body-2 d-inline-block text-truncate" style="max-width: 80%;" v-if="group.description">{{ group.description }}</span>
                                    <span class="d-block body-2 text--disabled font-italic" v-else> Sin especificar</span>
                                  </div>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-switch v-model="group.active" class="mt-n1" color="green-500" @change="isChangeList = true, setPermissionsByGroups(group)" :ripple="false" hide-details absolute />
                                </v-list-item-subtitle>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          </template>
                          <v-empty-state v-else class="mt-n3" type="groups" id="groups" :isFree="true" title="No existen roles" customClass="mt-5" :height="200" />
                        </template>
                      </v-col>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <!-- end roles -->

            <!-- permissions -->
            <v-col class="pa-0" v-if="loadingDetail">
              <v-skeleton-loader class="skeleton-group rounded-t-md" tile transition="fade-transition" type="image" :height="200" />
              <v-divider/>
              <v-skeleton-loader class="skeleton-group" tile transition="fade-transition" type="image" :height="200" />
              <v-divider/>
              <v-skeleton-loader class="skeleton-group rounded-b-md" tile transition="fade-transition" type="image" :height="200" />
            </v-col>
            <v-row class="mt-5" v-else align="start" no-gutters>
              <v-col class="px-0">
                <v-card class="pa-0 white-0" :class="item.parent ? roundedByCategory(item.category) : 'my-5'" flat v-for="(item, i) in list" :key="i">
                  <div >
                    <template v-if="item.parent === 'Configuraciones' && item.category === 'Organización'">
                      <v-toolbar color="transparent" flat height="46">
                        <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                          <v-row align="center" no-gutters>
                            <v-icon size="18" left color="grey-500">mdi-file-document</v-icon>
                            Configuración
                          </v-row>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-divider />
                    </template>
                    <template v-if="item.parent === 'Recepción' && item.category === 'Documentos'">
                      <v-toolbar color="transparent" flat height="46">
                        <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                          <v-row align="center" no-gutters>
                            <v-icon size="18" left color="grey-500">mdi-file-document</v-icon>
                            Recepción
                          </v-row>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-divider />
                    </template>
                    <v-toolbar color="transparent" flat height="46">
                      <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                        <v-row align="center" no-gutters>
                          <v-icon size="18" left color="grey-500">mdi-{{ item.icon }}</v-icon>
                          {{item.category === 'SII' ? $t('generals.SII') : item.category}}
                        </v-row>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-col class="px-5">
                        <skeleton-groups v-if="loadingDetail" type="permissions" />
                        <template v-else>
                          <v-col class="pa-0" v-for="(c, index) in item.children" :key="index">
                            <v-list class="v-list-1 transparent pa-0">
                              <v-list-item class="pa-0">
                                <v-list-item-title class="grey-300--text">
                                  <span v-if="c.key !== 'dtes'">
                                    {{c.name}}
                                    <v-chip class="caption pa-2 ml-2" v-if="c.assignedByGroups?.length" color="grey-background" text-color="#9FA8DA" x-small>
                                      Asignado a través del rol “
                                      <span class="d-inline-block text-truncate" style="max-width: 200px;">
                                        <template v-if="c.assignedByGroups.length > 2">{{ c.assignedByGroups.slice(0,2).toString().replace(',', ', ') }}</template>
                                        <template v-else>{{ c.assignedByGroups.toString().replace(',', ' y ')}}</template>
                                      </span>
                                      <span v-if="c.assignedByGroups.length > 2" class="ml-1"> y {{ c.assignedByGroups.length - 2 }} {{ (c.assignedByGroups.length - 2) !== 1 ? 'roles' : 'rol' }} más </span>”
                                    </v-chip>
                                  </span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-switch class="mt-n1" color="green-500" v-model="c.active" v-if="c.key !== 'dtes'" :disabled="!!c.assignedByGroups?.length" :ripple="false" hide-details @change="isChangeList = true, editable.permissions = [...list.filter((dte) => dte.active)]" />
                                </v-list-item-subtitle>
                              </v-list-item>
                              <div class="mt-n7" v-if="c.key === 'dtes'">
                                <v-list-item class="pa-0" v-for="(doc, i) in currentDocumentList" :key="i" inactive>
                                  <v-list-item-title class="grey-300--text">
                                    {{doc.name}}
                                    <v-chip class="caption pa-2 mx-2" v-if="doc.assignedByGroups?.length && doc.active" color="background" text-color="#9FA8DA" x-small>
                                      Asignado a través del rol “
                                      <span class="d-inline-block text-truncate" style="max-width: 200px;">
                                        <template v-if="doc.assignedByGroups.length > 2">{{ doc.assignedByGroups.slice(0,2).toString().replace(',', ', ') }}</template>
                                        <template v-else>{{ doc.assignedByGroups.toString().replace(',', ' y ')}}</template>
                                      </span>
                                      <span v-if="doc.assignedByGroups.length > 2 && doc.name.includes('Crear') " class="ml-1"> y {{ doc.assignedByGroups.length - 2 }} {{ (c.assignedByGroups.length - 2) !== 1 ? 'roles' : 'rol' }} más </span>”
                                    </v-chip>
                                    <v-chip class="caption pa-2 mr-2" color="background" text-color="#9FA8DA" x-small v-if="doc.name.includes('Ver') && currentDocumentList[i - 1]?.active && doc.active">
                                      <span class="d-inline-block text-truncate" style="max-width: 200px;">
                                        Obligatorio, si la opción {{currentDocumentList[i - 1].name.toLowerCase()}} está activa
                                      </span>
                                    </v-chip>
                                  </v-list-item-title>
                                  <v-list-item-subtitle class="mr-0">
                                    <v-switch class="mt-n1" v-model="doc.active" @click="item.selectOrDeselectAllPermission = currentDocumentList.every((item) => item?.active), isChangeList = true, enabledTooltip(doc, i, index)" color="green-500" :ripple="false" :disabled="(doc.name.includes('Ver') && currentDocumentList[i - 1]?.active)|| !!doc.assignedByGroups?.length " hide-details />
                                  </v-list-item-subtitle>
                                </v-list-item>
                                <v-col :class="['pa-0 mt-2', {'mb-6' : $store.state.auth.account.country !== 'PE'}]" v-if="item.category === 'Emisión'">
                                  <v-list class="v-list-1 transparent pa-0">
                                    <v-list-item class="pa-0">
                                      <v-list-item-title class="font-weight-bold grey-300--text">Seleccionar todos los DTE’s</v-list-item-title>
                                      <v-list-item-subtitle>
                                        <v-switch v-model="item.selectOrDeselectAllPermission" class="mt-n1" @change="selectAllPermissions(item.category, $event), isChangeList = true" :disabled="currentDocumentList.filter((d) => d.name.includes('Crear')).every((dte) => dte?.active && !!dte.assignedByGroups?.length)" color="green-500" :ripple="false" hide-details />
                                      </v-list-item-subtitle>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <div class="mt-n3" v-if="$store.state.auth.account.country !== 'PE'" style="border-top: 1px solid var(--light-blue-grey); position: absolute; width: 100%; left: 0; right: 0"></div>
                              </div>
                            </v-list>
                          </v-col>
                        </template>
                      </v-col>
                    </v-card-text>
                    <v-divider v-if="list.length -1 !== i && (['Configuraciones'].includes(item.parent) || (['Documentos', 'Emisión por lotes', 'Emisión'].includes(item.category) && $store.state.auth.account.country !== 'PE'))" />
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <!-- end permissions -->
          </div>
        </div>
      </v-col>
    </v-col>
    <div class="text-end white-0 mt-6 pa-0 pagination-permissions">
      <v-divider />
      <v-col class="px-0">
        <v-btn
          class="px-4"
         :to="{ name: 'AccountMembers', params: this.$route.params}"
          outlined
        >Cancelar</v-btn>
        <v-btn
          class="px-4 ml-2 mr-6"
          @click="update()"
          :loading="loadingUpdate"
          :disabled="!isChangeList"
          color="blue-500"
        >
          Guardar usuario
        </v-btn>
      </v-col>
    </div>
    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="751" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Desactivar usuarios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-member.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-0 py-5">
              <span class="d-block">¿Qué ocurre cuando se desactiva un usuario?</span>
              <span class="d-block mt-4">1. El usuario ya no podrá trabajar en el espacio de trabajo.</span>
              <span class="d-block mt-2 mb-3">2. Toda la información creada por el usuario seguirá estando disponible.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="deleteMember" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog groups -->
    <v-dialog v-model="dialogPermissions" width="500" persistent no-click-animation overlay-color="grey-500" :scrollable="true">
      <v-card max-height="600">
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">{{ currentGroup.name }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3" no-gutters>
                <v-btn @click="handlerGroupDialog" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 grey-700--text" style="min-height:360px">
          <!-- permissions -->
          <v-col class="pa-0" v-if="loadingDetailByGroup">
            <v-skeleton-loader class="skeleton-group rounded-t-md" tile transition="fade-transition" type="image" :height="200" />
            <v-divider/>
            <v-skeleton-loader class="skeleton-group" tile transition="fade-transition" type="image" :height="200" />
            <v-divider/>
            <v-skeleton-loader class="skeleton-group rounded-b-md" tile transition="fade-transition" type="image" :height="200" />
          </v-col>
          <template v-else>
            <v-row align="start" no-gutters v-if="currentGroup?.list?.length">
              <v-col class="px-0">
                <v-card class="pa-0 white-0" flat>
                  <div v-for="(item, i) in currentGroup.list" :key="i">
                    <v-toolbar color="transparent" flat height="46">
                      <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                        <v-row align="center" no-gutters>
                          <v-icon size="18" left color="grey-500">mdi-{{ item.icon }}</v-icon>
                          {{item.category === 'SII' ? $t('generals.SII') : item.category}}
                        </v-row>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-col class="px-5">
                        <skeleton-groups v-if="loadingDetail" type="permissions" />
                        <template v-else>
                          <v-col class="pa-0" v-for="(c, index) in item.children" :key="index">
                            <v-list class="v-list-1 transparent pa-0">
                              <v-list-item class="pa-0">
                                <v-list-item-title class="grey-300--text">
                                  <span v-if="c.key !== 'dtes'">{{c.name}}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-switch class="mt-n1" v-model="c.active" v-if="c.key !== 'dtes'" color="green-500" :ripple="false" hide-details @change="item.selectOrDeselectAllPermission = item.children.every((item) => item.active)" disabled dense/>
                                </v-list-item-subtitle>
                              </v-list-item>
                              <div class="mt-n7" v-if="c.key === 'dtes'">
                                <v-list-item class="pa-0" v-for="(doc, i) in currentDocumentListGroup" :key="i" inactive>
                                  <v-list-item-title class="grey-300--text">{{doc.name}}
                                    <v-chip class="caption pa-2" color="background" text-color="#9FA8DA" x-small v-if="doc.name.includes('Ver') && currentDocumentListGroup[i - 1].active">Obligatorio, si la opción {{currentDocumentListGroup[i - 1].name.toLowerCase()}} está activa</v-chip>
                                  </v-list-item-title>
                                  <v-list-item-subtitle class="mr-0">
                                    <v-switch class="mt-n1" v-model="doc.active" @click="enabledTooltip(doc, i, index), item.selectOrDeselectAllPermission = currentDocumentListGroup.every((item) => item.active)" color="green-500" :ripple="false" dense disabled hide-details />
                                  </v-list-item-subtitle>
                                </v-list-item>
                              </div>
                            </v-list>
                          </v-col>
                        </template>
                      </v-col>
                    </v-card-text>
                    <v-divider v-if="list.length -1 !== i" />
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <!-- end permissions -->
            <v-empty-state v-else type="suppliers" id="miembros" :isFree="true" title="No existen usuarios" />
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn :to="{ name: 'AccountGroupsUpdate', params: {id: currentGroup.id }}" target="_blank" outlined>Editar</v-btn>
          <v-btn color="blue-500" @click="handlerGroupDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog groups -->
  </v-row>
</template>
<script>
// import cloneDeep from 'lodash/cloneDeep'
import VEmptyState from '@/components/commons/VEmptyState'
import permissionList from '@/collections/permissionsList'
import { groupBy } from 'lodash'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import SkeletonGroups from '@/modules/account/views/groups/components/SkeletonGroups'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
// import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
  SkeletonGroups,
  SkeletonList,
  VTableHeaders,
  VEmptyState
},
  mixins: [
  GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    currentDocumentListGroup: [],
    dteOnebyOne: [],
    currentGroup: {},
    editable: {},
    currentGroupsList: [],
    headers: [
      { text: 'Nombre', value: 'first_name', sortable: true },
      // { text: 'Fecha de Ingreso al rol', value: 'created' },
      { text: '', value: 'actions', align: 'end' }
    ],
    loadingDetailByGroup: false,
    // isCreated: false,
    isChangeList: false,
    dialogPermissions: false,
    confirm: '',
    dialogDelete: false,
    panel: [],
    selectOrDeselectAllPermissionDTE: false,
    isNotError: true,
    permissionList,
    changePermissions: false,
    changeMembers: false,
    list: [],
    summaryList: [],
    resetSwitch: 0,
    loading: false,
    loadingMembers: false,
    loadingDetail: false,
    selectOrDeselectAllPermission: false,
    currentMembersList: [],
    permissionsList: [],
    currentCategory: '',
    loadingUpdate: false,
    documentTypes: DocumentTypes,
    currentDocumentList: [],
    typeSort: 'asc',
    parents: {
      Configuraciones: ['Organización', 'Usuarios', 'Roles', 'Developers', 'Notificaciones'],
      Emisión: ['Emisión', 'Emisión por lotes', 'Clientes'],
      Recepción: ['Documentos', 'Proveedores']
    },
    currentDocumentListRoles: [],
    currentDocumentListUsers: []
  }),
  computed: {
    ...mapState({
      membersList: state => state.accounts.membersList,
      groupsList: state => state.accounts.groupsList,
      groupDetail: state => state.accounts.groupsDetail
    }),
    roundedByCategory () {
      return (category) => {
        if (['Roles', 'Usuarios', 'Notificaciones', 'Emisión por lotes'].includes(category)) return 'rounded-0'
        if (['Organización', 'Emisión', 'Documentos'].includes(category)) return 'rounded-b-0'
        if (['Developers', 'Clientes'].includes(category)) return 'rounded-t-0'
        if (['Proveedores'].includes(category)) return 'rounded-t-0 mb-5'

        else return ''
      }
    }
  },
  created () {
    this.permissionsList = JSON.parse(JSON.stringify(permissionList))
    if (this.$helpers.hasPermission(['view_group'])) {
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'groups',
        query: {
          ...this.$route.query,
          page_size: 9999
        }
      })
      .then(() => {
        this.getMember()
      })
      .finally(() => {
        this.loading = false
      })
    } else {
      this.getMember()
    }
  },
  methods: {
    getMember () {
      this.loadingMembers = true
      this.$store.dispatch('accounts/LIST', {
        resource: `members/${this.$route.params.id}`,
        query: {
          ...this.$route.query
        }
      })
      .then((response) => {
        const result = response.data
        /** set groups list */
        this.currentGroupsList = [...this.groupsList]

        this.currentGroupsList.forEach((item) => {
          item.active = result.groups.map(({id}) => id).includes(item.id)
        })
        /** end set groups list */

        this.editable = {
          id: result.id,
          is_owner: result.is_owner,
          is_active: result.is_active,
          name: `${result.first_name} ${result.last_name}`,
          email: result.email,
          permissions: result.permissions,
          document_type_permissions: result.document_type_permissions
        }

        let permissionByGroupsList = []
        this.currentGroupsList.filter(({active}) => active).forEach(({permissions}) => {
          permissionByGroupsList = [...permissionByGroupsList, ...permissions]
        })

        permissionByGroupsList = [...new Set([...permissionByGroupsList, ...this.editable.permissions])]

        this.list = [...this.permissionsList]
        this.list.forEach((item) => {
          if (permissionByGroupsList.includes(item.id)) {
            item.active = true
            this.currentGroupsList.filter(({active}) => active).forEach((group) => {
              if (group.permissions.find((g) => g === item.id)) {
                item.assignedByGroups = [...item.assignedByGroups, group.name]
              }
            })
          }
        })
        this.list = [...this.groupBy(this.list)]

        /** set documentType */

        this.setPermissionsByDTEGroups(response.data.document_type_permissions)
        /** end set documentType */
      })
      .finally(() => {
        this.loadingMembers = false
      })
    },
    groupBy (list = []) {
      return Object.entries(groupBy(list, 'category')).map(([key, value]) => {
        return {
          category: key,
          icon: value[0].icon,
          selectOrDeselectAllPermission: false,
          parent: value.find(({parent}) => this.parents[parent]?.includes(key))?.parent ?? '',
          children: value.filter(({countries}) => countries.includes(this.$store?.state?.auth?.account?.country))
        }
      }).filter(({children}) => children.length)
    },
    selectAllPermissions (category, e) {
      this.list.forEach((item) => {
        if (item.category === category && category !== 'Emisión') {
          item.children.forEach((c) => {
            c.active = e
          })
        }
      })
      if (this.currentDocumentList.length && category === 'Emisión') {
        this.currentDocumentList.forEach((d, index) => {
          if (!d.assignedByGroups.length && !(this.currentDocumentList[index - 1]?.assignedByGroups?.length && d.name.includes('Ver'))) d.active = e
        })
      }
    },
    setPermissionsByGroups (group) {
      this.setDTE(group)
      this.list.forEach((elm) => {
        elm.children.forEach((item) => {
          if (group.permissions.includes(item.id)) {
            if (!group.active) {
              item.assignedByGroups = [...item.assignedByGroups.filter((g) => g !== group.name)]
            } else item.assignedByGroups.push(group.name)
            item.active = item.assignedByGroups.length > 0 || this.editable.permissions.includes(item.id)
          }
          if (elm.category === 'Emisión') {
            this.currentDocumentList.forEach((dte, index) => {
              if (dte.name.includes('Ver')) {
                dte.active = this.editable.document_type_permissions?.some((p) => p.permission === 'view_document' && dte.id === p.document_type) || !!this.currentDocumentList[index - 1]?.assignedByGroups?.length
              } else {
                dte.active = this.editable.document_type_permissions?.some((p) => p.permission === 'add_document' && dte.id === p.document_type) || !!dte.assignedByGroups.length
              }
            })
            elm.selectOrDeselectAllPermission = this.currentDocumentList.every(({active}) => active)
          }
        })
      })
    },
    enabledTooltip (doc = {}) {
      if (doc.name.includes('Crear')) {
        const current = doc.name.replace('Crear', '').trimStart()
        const index = this.currentDocumentList.findIndex(({name}) => name === `Ver ${current}`)
        if (index !== -1 && doc.active) {
          this.currentDocumentList[index].active = true
          this.currentDocumentList[index].activeTooltip = true
        } else {
          this.currentDocumentList[index].activeTooltip = false
        }
      }

       this.editable.document_type_permissions = [...this.currentDocumentList.filter((dte) => dte.active)]
       this.editable.document_type_permissions = this.editable.document_type_permissions.map((d) => {
        return {
          permission: d.name.includes('Ver') ? 'view_document' : 'add_document',
          document_type: d.id
        }
      })
    },
    handlerGroupDialog () {
      this.dialogPermissions = false
      if (this.$route.query.users) {
        this.$router.replace({ query: null }).catch(() => {})
      }
    },
    /* eslint-disable */
    handleGroup (group) {
      this.dialogPermissions = true
      this.currentGroup = group

      this.loadingDetailByGroup = true
      this.$store.dispatch('accounts/RETRIEVE', {
        resource: 'groups',
        id: group.id,
        loader: true
      })
      .then((response) => {
        const { name, description, permissions, document_type_permissions } = response.data
        this.name = name
        this.description = description
        this.currentGroup.list = JSON.parse(JSON.stringify(permissionList))
         this.currentGroup.list.forEach((item) => {
          if (permissions.includes(item.id)) {
            item.active = true
          }
        })
         this.currentGroup.list = [...this.groupBy(this.currentGroup.list)]
        /** set documentType */
        this.currentDocumentListGroup = []
        let auxArray = []
        this.currentGroup.list = this.groupBy(JSON.parse(JSON.stringify(permissionList)))
        let array = [...this.documentTypes?.filter(({country}) => this.$store?.state?.auth?.account?.country === country)]
        array.forEach((item) => {
          auxArray = [...auxArray, ...[`Crear ${item.name.toLowerCase()}`, `Ver ${item.name.toLowerCase()}`]]
        })
        let foundDocAdd = {}
        let foundDocView = {}
        auxArray.forEach((a) => {
          foundDocAdd = array.find((item) => item.name.toLowerCase() === a.replace('Crear', '').trimStart())
          if (foundDocAdd !== undefined) {
            this.currentDocumentListGroup = [...this.currentDocumentListGroup, {...foundDocAdd, ...{ name: a, active: false, assignedByGroups: [] }}]
          }
          foundDocView = array.find((item) => item.name.toLowerCase() === a.replace('Ver', '').trimStart())
          if (foundDocView !== undefined) {
            this.currentDocumentListGroup = [...this.currentDocumentListGroup, {...foundDocView, ...{ name: a, active: false, assignedByGroups: [] }}]
          }
        })
        let arrayDTE = document_type_permissions
          arrayDTE.forEach((doc) => {
            this.currentDocumentListGroup.forEach((item) => {
            if (doc.permission === 'add_document' && item.name.includes('Crear') && doc.document_type === item.id) item.active = doc.permission === 'add_document' && item.name.includes('Crear')
            if (doc.permission === 'view_document' && item.name.includes('Ver') && doc.document_type === item.id) item.active = doc.permission === 'view_document' && item.name.includes('Ver')
          })
        })
        /** end set documentType */
      })
      .finally(() => {
        this.loadingDetailByGroup = false
      })
    },
    setPermissionsByDTEGroups () {
      this.currentDocumentList = []
      let auxArray = []
      // this.list = this.groupBy(JSON.parse(JSON.stringify(permissionList)))
      let array = [...this.documentTypes?.filter(({country}) => this.$store?.state?.auth?.account?.country === country)]
      array.forEach((item) => {
        auxArray = [...auxArray, ...[`Crear ${item.name.toLowerCase()}`, `Ver ${item.name.toLowerCase()}`]]
      })
      let foundDocAdd = {}
      let foundDocView = {}
      auxArray.forEach((a) => {
        foundDocAdd = array.find((item) => item.name.toLowerCase() === a.replace('Crear', '').trimStart())
        if (foundDocAdd !== undefined) {
          this.currentDocumentList = [...this.currentDocumentList, {...foundDocAdd, ...{ name: a, active: false, assignedByGroups: [] }}]
        }
        foundDocView = array.find((item) => item.name.toLowerCase() === a.replace('Ver', '').trimStart())
        if (foundDocView !== undefined) {
          this.currentDocumentList = [...this.currentDocumentList, {...foundDocView, ...{ name: a, active: false, assignedByGroups: [] }}]
        }
      })
      if (this.currentGroupsList.filter(({active}) => active).length > 0) {
        this.currentGroupsList.filter(({active}) => active).forEach((group) => {
          this.setDTE(group)
        })
      } else {
        this.setDTE()
      }

      this.list.forEach((item) => {
        if (item.category !== 'Emisión') item.selectOrDeselectAllPermission = item.children.every(({active}) => active)
        else {
          item.selectOrDeselectAllPermission = this.currentDocumentList.every(({active}) => active)
        }
      })
    },
    setDTE (group = {}) {
      this.currentDocumentList.forEach((item, index) => {
        item.assignedByGroupsAux = []
        const found = group.document_type_permissions?.find((g) => g.document_type === item.id)
        if (found) {
          if (!group.active) {
            item.assignedByGroups = [...item.assignedByGroups.filter((g) => g !== group.name)]
          } else item.assignedByGroupsAux = [...item.assignedByGroupsAux, {name: group.name, permission: found.permission}]

          if (item.name.includes('Crear')) {
            item.assignedByGroups = [...item.assignedByGroups, ...item.assignedByGroupsAux.filter(({permission}) => permission === 'add_document').map(({name}) => name)]
            item.active = this.editable.document_type_permissions?.some((p) => p.permission === 'add_document' && item.id === p.document_type) || item.assignedByGroups?.length > 0
          }
          if (item.name.includes('Ver')) {
            item.assignedByGroups = [...item.assignedByGroups, ...item.assignedByGroupsAux.filter(({permission}) => permission === 'view_document').map(({name}) => name)]
            item.active = this.editable.document_type_permissions?.some((p) => p.permission === 'view_document' && item.id === p.document_type) || (item.assignedByGroups?.length > 0 || this.currentDocumentList[index - 1].assignedByGroups?.length > 0)
          }
        } else {
          if (item.name.includes('Crear')) {
            item.active = this.editable.document_type_permissions?.some((p) => p.permission === 'add_document' && item.id === p.document_type)
          }
          if (item.name.includes('Ver')) {
            item.active = this.editable.document_type_permissions?.some((p) => p.permission === 'view_document' && item.id === p.document_type)
          } 
        }
      })
    },
    update () {
      this.loadingUpdate = true
      let documentTypePermissions = []
      if (this.currentDocumentList.length) {
        this.currentDocumentList.forEach((item, index) => {
          if (!item.assignedByGroups.length && (!this.currentDocumentList[index - 1]?.assignedByGroups?.length > 0)) {
            if (item.active) {
              documentTypePermissions.push({
                document_type: item.id,
                permission: item.name.includes('Crear') ? 'add_document' : 'view_document'
              })
            }
          }
        })
      }
      let permissionsList = []
      this.list.forEach((item) => {
        permissionsList = [...permissionsList, ...item.children?.filter(({active, assignedByGroups}) => active && !assignedByGroups.length).map(({id}) => id)]
      })
      let payload = { groups: this.currentGroupsList?.filter(({active}) => active).map(({id}) => id) ?? [], document_type_permissions: documentTypePermissions, permissions: permissionsList}
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'members',
        id: this.editable.id,
        payload
      })
      .then((response) => {
        const { name, description, permissions } = response.data
        this.name = name
        this.description = description
        this.list = [...this.permissionsList]
        this.list.forEach((item) => {
          if (permissions.includes(item.id)) {
            item.active = true
          }
        })
        this.list = this.groupBy(this.list)
        this.$dialog.message.info('El usuario ha sido actualizado con éxito')
        this.$router.push({ name: 'AccountMembers', ...this.$route.params })
      })
      .catch((error) => {
        this.isNotError = false
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loadingUpdate = false
      })
    },
    deleteMember () {
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'members',
        payload: {
          is_active: !this.editable.is_active
        },
        id: this.editable.id
      })
      .then(() => {
        this.$dialog.message.info(`El usuario ha sido ${!this.editable.is_active ? 'desactivado' : 'activado'} en la organización`)
        this.$router.replace({ name: 'AccountMembers', params: this.currentParams}).catch(() => {})
      })
      .catch((error) => {
        if (error.toString().search('400') !== -1) {
          this.dialogDelete = false
        }
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogDelete = false
      })
    }
  }
}
</script>